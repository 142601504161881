*{
  margin: 0;
  padding: 0;
}

html, body{
  width: 100%;
  height: 100%;
}

.ant-layout{
  min-height: 100vh;
}

.ant-popover {
  z-index: 1001;
}

.custom-input {
  /* Estilos padrão do input */
  background-color: white;
  padding: 6px 10px;
  transition: background-color 0.3s ease;

  /* Estilos quando o mouse passa sobre o input */
  &:hover {
    background-color: #e9e9e9;
  }
}

.ant-layout-header {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra ao header */
  /* Outros estilos para o header, se necessário */
}

.ant-card-meta-detail .ant-card-meta-title {
  color: rgba(0,0,0,0.55);
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.ant-card-meta-detail .ant-card-meta-description {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin: 0;
  padding: 0;
}

.centralizar-pdf {
  width: 100%;
  max-width: 800px; /* Defina o tamanho máximo conforme desejado */
  margin: 0 auto;
  border: 1px solid #ccc;
}

.borda-pdf {
  border: 1px solid #ccc;
}


.pdf-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pdf-footer {
  text-align: center;
}

.pdf-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-draggable, .cursor {
  cursor: move;
}
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}

.react-pdf__Page {
  z-index: 1;
  width: 100%;
  height: 100%;
  /*Optional*/
  position: absolute !important;
  top: 0;
  left: 0;
  /*Optional*/
}
.react-pdf__Page__textContent {
  border: 1px solid #a9a9a9;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

  /*Optional*/
.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}
/*Optional*/

  /*Optional*/
.react-pdf__Page__canvas {
  margin: 0 auto;
}
/*Optional*/

/*Optional*/
/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
/*Optional*/

#drawArea {
  width: 612px;
  height: 850px;
  /* border: 1px solid black; */
  position: relative;
}

.crosshair {
  cursor: crosshair;
}

/* svg {
  z-index: 1;
  position: absolute !important;
  top: 0;
  left: 0;
} */

.path {
  fill: none;
  stroke-width: 1px;
  stroke: red;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
}
